<template>
    <v-dialog width="500" v-model="show" scrollable>
        <v-card>
            <v-card-title>Просмотр</v-card-title>
            <v-card-text v-if="info">
                <v-text-field label="Фамилия" :value="info.surname" readonly />
                <v-text-field label="Имя" :value="info.name" readonly />
                <v-text-field label="Отчество" :value="info.patronymic" readonly />
                <v-text-field label="Организация" :value="info.org" readonly />
                <v-text-field label="Email" :value="info.email" readonly />
                <v-text-field label="Телефон" :value="info.phone" readonly />
                <v-text-field label="Адрес" :value="info.adr" readonly />
                <v-textarea label="Текст" :value="info.text" readonly />
                <v-list>
                    <v-list-item v-for="f in info.contactRequestFiles" :key="f.id">
                        <v-list-item-title>{{ f.name }}</v-list-item-title>
                        <v-list-item-action>
                            <v-btn icon :href="getDownloadLink(f.realName)" target="_blank" download>
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="error" @click="close">Закрыть</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template> 

<script>
export default {
    data: () => ({
        show: false,
        info: null,
    }),
    methods: {
        open(info) {
            this.info = info;

            this.show = true;
        },
        close() {
            this.info = null;

            this.show = false;
        },
        getDownloadLink(name) {
            return `${process.env.VUE_APP_API}/uploads/contactrequest/${this.info.id}/${name}`;
        }
    },
};
</script>
