<template>
    <div>
        <v-overlay v-model="loading">
            <v-progress-circular indeterminate />
        </v-overlay>
        <v-simple-table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Фамилия</th>
                    <th>Имя</th>
                    <th>Файлы</th>
                    <th>Email</th>
                    <th>Дата</th>
                    <th></th>
                </tr>
            </thead>
            <tfoot v-if="pg > 1 || total > 1">
                <tr>
                    <td colspan="7">
                        <v-pagination 
                            :length="total"
                            :value="pg"
                            @input="changePg"
                        />
                    </td>
                </tr>
            </tfoot>
            <tbody>
                <tr v-for="l in list" :key="l.id">
                    <td>{{ l.id }}</td>
                    <td>{{ l.surname }}</td>
                    <td>{{ l.name }}</td>
                    <td>{{ l.contactRequestFiles.length }}</td>
                    <td>{{ l.email }}</td>
                    <td>{{ l.created }}</td>
                    <td>
                        <v-btn icon color="primary" @click="open(l)">
                            <v-icon>mdi-magnify-plus</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <InfoDialog ref="info" />
    </div>
</template> 

<script>
import InfoDialog from '@c/contact-request/InfoDialog.vue';

export default {
    props: {
        pg: {
            type: Number,
            required: true,
        }
    },
    components: {
        InfoDialog,
    },
    data: () => ({
        loading: false,
        list: [],
        total: 0,
    }),
    watch: {
        pg() {
            this.getData();
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        open(info) {
            this.$refs.info.open(info);
        },
        async getData() {
            this.loading = true;

            const {list, total} = await this.$api(`/admin/contact-request?pg=${this.pg}`);

            this.list = list.map(l => {
                l.created = new Date(l.created).toLocaleString();

                return l;
            });
            this.total = total;

            this.loading = false;
        },
        changePg(pg) {
            if (pg == this.pg) {
                return;
            }

            this.$router.push({
                query: {
                    pg,
                }
            });
        },
    },
};
</script>